import React from 'react';
import { graphql, PageProps } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.NotFoundPageQuery;

const NotFoundPage = ({ data }: PageProps<Props>) => (
  <Layout>
    <BackgroundImage
      fluid={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
      className="flex grow py-3"
    >
      <blockquote className="m-auto w-full bg-gray-50 bg-opacity-75 p-4 shadow-lg sm:w-auto sm:max-w-3xl sm:rounded-lg sm:p-6">
        <div className="text-center text-2xl font-medium leading-9 text-gray-900">
          <p>
            &ldquo;It seems your journey to find the answer to the ultimate question of life, the
            universe, and everything has taken a wrong turn.&rdquo;
          </p>
          <p className="text-sm text-gray-500">
            &hellip;and in case you were wondering what the answer is, it&rsquo;s 42.
          </p>
        </div>
      </blockquote>
    </BackgroundImage>
  </Layout>
);

export const Head = () => <SEO title="404: Not found" />;

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPage {
    background: file(relativePath: { eq: "backgrounds/IMG_3750.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
